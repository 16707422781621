import React from "react";
import { Helmet } from "react-helmet";
import { SiteData } from "../../consts/SiteData";
import { Container, Header, Icon } from "semantic-ui-react";
import { MainContents } from "../../components/MainContents";
import PageLayout from "../../components/PageLayout";
import { URIs } from "../../consts/URIs";

const HamsterRunPage = () => {
  function createMarkup() {
    return {
      __html:
        '<iframe frameborder="0" height="365" scrolling="no" src="https://camp-fire.jp/projects/503671/widget" width="245"></iframe>',
    };
  }
  return (
    <PageLayout>
      <Helmet>
        <html lang="ja" />
        <title>{SiteData.PAGES.HAMSTER_RUN.TITLE}</title>
      </Helmet>
      <MainContents pageTitle="ハムスター健康管理アプリ「HamsterRun」">
        <Container style={{ padding: "2em 1em" }}>
          <Header as="h2">運動記録公開中</Header>
          <p>
            Clarity
            Solutionsで飼育中のジャンガリアンハムスター「ほね」の日々の運動量（回し車の回転数）を公開中です。
          </p>
          <Header
            as="a"
            href={URIs.HAMSTER_RUN}
            target="_blank"
            rel="noreferrer"
            icon="external alternate"
            color="blue"
          >
            Hamster Run アプリで「ほね」の運動記録をチェック
          </Header>
          <Icon
            name="external alternate"
            color="blue"
            style={{ fontSize: "1em", padding: "0 3px" }}
          />
        </Container>
        <Container style={{ padding: "2em 1em" }}>
          <Header as="h2">クラウドファンディングを実施</Header>
          <p>
            2021年10月22日から2022年の12月18日までの間、IOT回し車ガジェット量産体制整備のためのクラウドファンディングをCAMPFIREで実施しました。
          </p>
          <Container textAlign="center">
            <Header
              as="a"
              href={URIs.HAMSTER_RUN_CUMPFIRE}
              target="_blank"
              rel="noreferrer"
              icon="external alternate"
              color="blue"
            >
              CAMPFIRE
            </Header>
            <Icon
              name="external alternate"
              color="blue"
              style={{ fontSize: "1em", padding: "0 3px" }}
            />
            <div className="text" dangerouslySetInnerHTML={createMarkup()} />
          </Container>
        </Container>
      </MainContents>
    </PageLayout>
  );
};

export default HamsterRunPage;
